(window as any).global = window;
(window as any).process = {
  env: { DEBUG: undefined },
};

interface Window {
  InboxEase: any;
  Stripo: any;
  StripoApi: any;

  // Functions for Stripo.
  getTemplate: any;
  initPlugin: any;
  initPluginAndCompile: any;
  compileEmail: any;
}
